
$inputHeightDesktop: 38px;

// FormInput
.formInputContainer {
  margin: 20px 0; }

.formInputFrame {
  border: 1.3px solid var(--ct-input-border-color);
  position: relative;
  border-radius: 2px;
  &[focus='focus'] {
    border: 1.3px solid var(--ct-primary); }
  &[error='error'] {
    border: 1.3px solid var(--ct-danger); }
  input {
    color: var(--ct-input-color);
    border: none;
    border-radius: 0;
    height: $inputHeightDesktop;
    background: transparent;
    padding: 0 13px;
    font-size: 16px;
    width: calc(100% - 26px); } }

.formInputLabel {
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
  color: var(--ct-input-placeholder-color);
  transition: ease-in-out .1s;
  cursor: text;
  &[label_position='top'] {
    top: -2.5px;
    padding: 0 3px;
    background-color: var(--ct-card-bg); }
  &[focus='focus'] {
    color: var(--ct-primary); }
  &[error='error'] {
    color: var(--ct-danger); } }

.formInputErrorFrame {
  label {
    color: var(--ct-danger); } }
