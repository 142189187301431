// Device Grid
.deviceGridContainer {
  display: grid;
  margin: 15px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  @media only screen and (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
  @media only screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr; }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr; }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr; } }

// Device Item
.deviceItemContainer {
  background-color: var(--ct-card-bg);
  padding: 15px;
  box-shadow: var(--ct-box-shadow);
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
.deviceItemNameFrame {
  margin-top: 10px;
  strong {
    font-size: 18px; } }
