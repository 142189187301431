// Modal Provider
.modalProviderContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--ct-modal-content-bg);
  z-index: 1;
  padding: 20px;
  border-radius: 3px;
  min-width: 350px; }

.modalProviderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.modalProviderCloseButtonFrame {
  button {
    height: 35px;
    width: 35px;
    position: relative;
    border-radius: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: .1s background-color ease-in-out;
    &:hover {
      background-color: rgba(100,100,100,.15); }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: var(--ct-body-color); } } }


// Modal Body
.modalBodyContainer {
  padding: 20px 0;
  max-height: 65vh;
  overflow-y: auto; }

// Modal Footer
.modalFooterContainer {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
