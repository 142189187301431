
$mobile-screen-width: 700px;

html {
  --ct-blue: #3d73dd;
  --ct-indigo: #727cf5;
  --ct-purple: #536de6;
  --ct-pink: #ff679b;
  --ct-red: #ff5b5b;
  --ct-orange: #fd7e14;
  --ct-yellow: #f9c851;
  --ct-green: #10c469;
  --ct-teal: #02a8b5;
  --ct-cyan: #35b8e0;
  --ct-white: #fff;
  --ct-gray: #98a6ad;
  --ct-gray-dark: #343a40;
  --ct-gray-100: #f1f3fa;
  --ct-gray-200: #eef2f7;
  --ct-gray-300: #dee2e6;
  --ct-gray-400: #ced4da;
  --ct-gray-500: #adb5bd;
  --ct-gray-600: #98a6ad;
  --ct-gray-700: #6c757d;
  --ct-gray-800: #343a40;
  --ct-gray-900: #323a46;
  --ct-primary: #536de6;
  --ct-secondary: #6c757d;
  --ct-success: #10c469;
  --ct-info: #35b8e0;
  --ct-warning: #f9c851;
  --ct-danger: #ff5b5b;
  --ct-light: #eef2f7;
  --ct-dark: #323a46;
  --ct-primary-rgb: 83,109,230;
  --ct-secondary-rgb: 108,117,125;
  --ct-success-rgb: 16,196,105;
  --ct-info-rgb: 53,184,224;
  --ct-warning-rgb: 249,200,81;
  --ct-danger-rgb: 255,91,91;
  --ct-light-rgb: 238,242,247;
  --ct-dark-rgb: 50,58,70;
  --ct-white-rgb: 255,255,255;
  --ct-black-rgb: 0,0,0;
  --ct-body-color-rgb: 108,117,125;
  --ct-body-bg-rgb: 250,251,254;
  --ct-font-sans-serif: "Nunito",sans-serif;
  --ct-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --ct-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --ct-body-font-family: Nunito,sans-serif;
  --ct-body-font-size: 0.9rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #6c757d;
  --ct-body-bg: #eff0f3; }

html {
  --ct-link-color: #536de6;
  --ct-link-hover-color: #475dc4;
  --ct-border-color: #dee2e6;
  --ct-loader-bg: #333333;
  --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-box-shadow-sm: 0 .125rem .25rem rgba(0, 0, 0, 0.075);
  --ct-box-shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  --ct-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --ct-component-active-color: #fff;
  --ct-component-active-bg: #536de6;
  --ct-text-muted: #98a6ad;
  --ct-blockquote-footer-color: #98a6ad;
  --ct-hr-color: #ced4da;
  --ct-mark-bg: #fcf8e3;
  --ct-table-color: #6c757d;
  --ct-table-bg: transparent;
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: #6c757d;
  --ct-table-striped-bg: rgba(241, 243, 250, 0.8);
  --ct-table-active-color: var(--ct-table-color);
  --ct-table-active-bg: rgba(222, 226, 230, 0.4);
  --ct-table-hover-color: var(--ct-table-color);
  --ct-table-hover-bg: #f1f3fa;
  --ct-table-border-color: #eef2f7;
  --ct-table-group-separator-color: #edeff1;
  --ct-table-caption-color: var(--ct-text-muted);
  --ct-input-btn-focus-color: rgba(83, 109, 230, 0.25);
  --ct-btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-link-color: var(--ct-link-color);
  --ct-btn-link-hover-color: var(--ct-link-hover-color);
  --ct-btn-link-disabled-color: #adb5bd;
  --ct-form-text-color: var(--ct-text-muted);
  --ct-input-bg: #fff;
  --ct-input-disabled-bg: #eef2f7;
  --ct-input-color: #6c757d;
  --ct-input-border-color: #dee2e6;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: var(--ct-input-bg);
  --ct-input-focus-border-color: #c8cbcf;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #adb5bd;
  --ct-input-plaintext-color: #6c757d;
  --ct-form-check-input-bg: #fff;
  --ct-form-check-input-border: 1px solid #dee2e6;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-form-switch-color: #dee2e6;
  --ct-input-group-addon-color: var(--ct-input-color);
  --ct-input-group-addon-bg: #eef2f7;
  --ct-input-group-addon-border-color: #dee2e6;
  --ct-form-select-color: var(--ct-input-color);
  --ct-form-select-bg: var(--ct-input-bg);
  --ct-form-select-disabled-color: #98a6ad;
  --ct-form-select-disabled-bg: #eef2f7;
  --ct-form-select-indicator-color: #343a40;
  --ct-form-range-track-bg: #dee2e6;
  --ct-form-range-track-box-shadow: var(--ct-box-shadow-inset);
  --ct-form-range-thumb-box-shadow: 0 .1rem .25rem rgba(0, 0, 0, 0.1);
  --ct-form-range-thumb-active-bg: #cbd3f8;
  --ct-form-range-thumb-disabled-bg: #adb5bd;
  --ct-form-file-button-color: var(--ct-input-color);
  --ct-form-file-button-bg: #eef2f7;
  --ct-form-file-button-hover-bg: #e2e6eb;
  --ct-nav-link-disabled-color: #98a6ad;
  --ct-nav-tabs-border-color: #dee2e6;
  --ct-nav-tabs-link-hover-border-color: #eef2f7 #eef2f7 var(--ct-nav-tabs-border-color);
  --ct-nav-tabs-link-active-color: #565e64;
  --ct-nav-tabs-link-active-bg: #fff;
  --ct-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 var(--ct-nav-tabs-link-active-bg);
  --ct-navbar-dark-color: rgba(255, 255, 255, 0.55);
  --ct-navbar-dark-hover-color: rgba(255, 255, 255, 0.75);
  --ct-navbar-dark-active-color: #fff;
  --ct-navbar-dark-disabled-color: rgba(255, 255, 255, 0.25);
  --ct-navbar-dark-toggler-border-color: rgba(255, 255, 255, 0.1);
  --ct-navbar-light-color: #676f77;
  --ct-navbar-light-hover-color: #343a40;
  --ct-navbar-light-active-color: rgba(0, 0, 0, 0.9);
  --ct-navbar-light-disabled-color: #adb5bd;
  --ct-dropdown-color: #6c757d;
  --ct-dropdown-bg: #fff;
  --ct-dropdown-border-color: #e7ebf0;
  --ct-dropdown-divider-bg: #e7ebf0;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #6c757d;
  --ct-dropdown-link-hover-color: #2d343f;
  --ct-dropdown-link-hover-bg: #f4f6fb;
  --ct-dropdown-link-active-color: #323a46;
  --ct-dropdown-link-active-bg: #f2f5f9;
  --ct-dropdown-link-disabled-color: #98a6ad;
  --ct-dropdown-dark-color: #dee2e6;
  --ct-dropdown-dark-bg: #343a40;
  --ct-dropdown-dark-border-color: var(--ct-dropdown-border-color);
  --ct-dropdown-dark-divider-bg: var(--ct-dropdown-divider-bg);
  --ct-dropdown-dark-link-color: var(--ct-dropdown-dark-color);
  --ct-dropdown-dark-link-hover-color: #fff;
  --ct-dropdown-dark-link-hover-bg: rgba(255, 255, 255, 0.15);
  --ct-dropdown-dark-link-active-color: var(--ct-dropdown-link-active-color);
  --ct-dropdown-dark-link-active-bg: var(--ct-dropdown-link-active-bg);
  --ct-dropdown-dark-link-disabled-color: #adb5bd;
  --ct-dropdown-dark-header-color: #adb5bd;
  --ct-pagination-color: #323a46;
  --ct-pagination-bg: #fff;
  --ct-pagination-border-color: #dee2e6;
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: #eef2f7;
  --ct-pagination-focus-box-shadow: 0 0 0 0.1rem var(--ct-input-btn-focus-color);
  --ct-pagination-hover-color: #323a46;
  --ct-pagination-hover-bg: #eef2f7;
  --ct-pagination-hover-border-color: #dee2e6;
  --ct-pagination-disabled-color: #98a6ad;
  --ct-pagination-disabled-bg: #fff;
  --ct-pagination-disabled-border-color: #dee2e6;
  --ct-card-border-color: #eef2f7;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #fff;
  --ct-card-bg: #fff;
  --ct-accordion-color: #6c757d;
  --ct-accordion-border-color: rgba(0, 0, 0, 0.125);
  --ct-accordion-button-active-bg: #eef0fd;
  --ct-accordion-button-active-color: #4b62cf;
  --ct-accordion-button-focus-border-color: var(--ct-input-focus-border-color);
  --ct-accordion-button-focus-box-shadow: 0 0 0 0.1rem var(--ct-input-btn-focus-color);
  --ct-tooltip-color: #f1f3fa;
  --ct-tooltip-bg: #343a40;
  --ct-popover-bg: #fff;
  --ct-popover-border-color: #dee2e6;
  --ct-popover-header-bg: #f1f3fa;
  --ct-popover-body-color: #6c757d;
  --ct-popover-arrow-color: #fff;
  --ct-popover-arrow-outer-color: #ced4da;
  --ct-toast-background-color: rgba(255, 255, 255, 0.85);
  --ct-toast-border-color: rgba(0, 0, 0, 0.1);
  --ct-toast-header-background-color: rgba(255, 255, 255, 0.85);
  --ct-toast-header-border-color: rgba(0, 0, 0, 0.05);
  --ct-badge-color: #fff;
  --ct-modal-content-bg: #fff;
  --ct-modal-content-box-shadow-xs: var(--ct-box-shadow-sm);
  --ct-modal-content-box-shadow-sm-up: var(--ct-box-shadow);
  --ct-modal-backdrop-bg: rgba(50, 58, 70, 0.54);
  --ct-modal-header-border-color: var(--ct-border-color);
  --ct-modal-footer-border-color: var(--ct-border-color);
  --ct-progress-bg: #eef2f7;
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #536de6;
  --ct-list-group-bg: #fff;
  --ct-list-group-border-color: #eef2f7;
  --ct-list-group-hover-bg: #f1f3fa;
  --ct-list-group-disabled-color: #6c757d;
  --ct-list-group-disabled-bg: #f5f7fc;
  --ct-list-group-action-color: #6c757d;
  --ct-list-group-action-active-color: #6c757d;
  --ct-list-group-action-active-bg: #eef2f7;
  --ct-thumbnail-bg: #fff;
  --ct-thumbnail-border-color: #dee2e6;
  --ct-thumbnail-box-shadow: var(--ct-box-shadow-sm);
  --ct-figure-caption-color: #98a6ad;
  --ct-breadcrumb-divider-color: #ced4da;
  --ct-breadcrumb-active-color: #adb5bd;
  --ct-carousel-control-color: #fff;
  --ct-carousel-indicator-active-bg: #fff;
  --ct-carousel-caption-color: #fff;
  --ct-carousel-dark-indicator-active-bg: #000;
  --ct-carousel-dark-caption-color: #000;
  --ct-btn-close-color: #323a46;
  --ct-code-color: #35b8e0;
  --ct-kbd-color: #fff;
  --ct-kbd-bg: #323a46;
  --ct-bg-leftbar: #fff;
  --ct-menu-item: #858788;
  --ct-menu-item-hover: #3d73dd;
  --ct-menu-item-active: #3d73dd;
  --ct-bg-topbar: #fff;
  --ct-bg-topbar-search: #f1f3fa;
  --ct-nav-user-bg-topbar: #3c4655;
  --ct-nav-user-border-topbar: #414d5d;
  --ct-bg-dark-topbar: #313a46;
  --ct-bg-dark-topbar-search: #3c4655;
  --ct-nav-user-bg-dark-topbar: #3c4655;
  --ct-nav-user-border-dark-topbar: #414d5d;
  --ct-rightbar-bg: #fff;
  --ct-rightbar-title-bg: #323a46;
  --ct-rightbar-title-color: #fff;
  --ct-rightbar-title-btn-bg: #444e5a;
  --ct-rightbar-title-btn-color: #fff;
  --ct-rightbar-overlay-bg: #37404a;
  --ct-bg-detached-leftbar: #fff;
  --ct-bg-leftbar-gradient: linear-gradient(135deg, #6379c3 0%, #546ee5 60%);
  --ct-bg-topnav: linear-gradient(to bottom, #6379c3, #546ee5);
  --ct-boxed-layout-bg: #fff;
  --ct-help-box-light-bg: rgba(255, 255, 255, 0.07);
  --ct-help-box-dark-bg: #3d73dd;
  --ct-nav-pills-bg: #eef2f7;
  --ct-custom-accordion-title-color: #313a46;
  --ct-dragula-bg: #f7f9fb;
  --ct-form-wizard-header-bg: #eef2f7;
  --ct-text-title-color: #6c757d;
  --ct-page-title-color: #6c757d;
  --ct-card-loader-bg: #313a46;
  --ct-chat-primary-user-bg: #fef5e4;
  --ct-chat-secondary-user-bg: #f1f3fa;
  --ct-auth-bg: #fff;
  --ct-apex-grid-color: #f9f9fd;
  --ct-hero-bg: linear-gradient(to bottom, #6379c3, #546ee5); }


html[theme=dark] {
  --ct-body-bg: #343a40;
  --ct-body-color: #aab8c5;
  --ct-link-color: #536de6;
  --ct-link-hover-color: #475dc4;
  --ct-border-color: #464f5b;
  --ct-loader-bg: #f3f3f3;
  --ct-box-shadow: 0px 0px 35px 0px rgba(49, 57, 66, 0.5);
  --ct-box-shadow-sm: 0 .125rem .25rem rgba(0, 0, 0, 0.075);
  --ct-box-shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  --ct-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --ct-component-active-color: #fff;
  --ct-component-active-bg: #536de6;
  --ct-text-muted: #8391a2;
  --ct-blockquote-footer-color: #ced4da;
  --ct-hr-color: inherit;
  --ct-mark-bg: #fcf8e3;
  --ct-table-color: #aab8c5;
  --ct-table-bg: transparent;
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: #aab8c5;
  --ct-table-striped-bg: rgba(64, 73, 84, 0.8);
  --ct-table-active-color: var(--ct-table-color);
  --ct-table-active-bg: rgba(70, 79, 91, 0.4);
  --ct-table-hover-color: var(--ct-table-color);
  --ct-table-hover-bg: #404954;
  --ct-table-border-color: #464f5b;
  --ct-table-group-separator-color: #4d5764;
  --ct-table-caption-color: var(--ct-text-muted);
  --ct-input-btn-focus-color: rgba(83, 109, 230, 0.25);
  --ct-btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-link-color: var(--ct-link-color);
  --ct-btn-link-hover-color: var(--ct-link-hover-color);
  --ct-btn-link-disabled-color: #aab8c5;
  --ct-form-text-color: var(--ct-text-muted);
  --ct-input-bg: #404954;
  --ct-input-disabled-bg: #37404a;
  --ct-input-color: #e3eaef;
  --ct-input-border-color: #4a525d;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: #464f5b;
  --ct-input-focus-border-color: #555f6b;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #8391a2;
  --ct-input-plaintext-color: #6c757d;
  --ct-form-check-input-bg: transparent;
  --ct-form-check-input-border: 1px solid #4a525d;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-input-group-addon-color: var(--ct-input-color);
  --ct-input-group-addon-bg: #48515d;
  --ct-input-group-addon-border-color: var(--ct-input-border-color);
  --ct-form-select-color: var(--ct-input-color);
  --ct-form-select-bg: var(--ct-input-bg);
  --ct-form-select-disabled-color: #ced4da;
  --ct-form-select-disabled-bg: #37404a;
  --ct-form-select-indicator-color: #e3eaef;
  --ct-form-range-track-bg: #464f5b;
  --ct-form-range-track-box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, 0.1);
  --ct-form-range-thumb-box-shadow: 0 .1rem .25rem rgba(0, 0, 0, 0.1);
  --ct-form-range-thumb-active-bg: #cbd3f8;
  --ct-form-range-thumb-disabled-bg: #aab8c5;
  --ct-form-file-button-color: var(--ct-input-color);
  --ct-form-file-button-bg: #48515d;
  --ct-form-file-button-hover-bg: #434b56;
  --ct-nav-link-disabled-color: #ced4da;
  --ct-nav-tabs-border-color: #464f5b;
  --ct-nav-tabs-link-hover-border-color: #37404a #37404a var(--ct-nav-tabs-border-color);
  --ct-nav-tabs-link-active-color: #e3eaef;
  --ct-nav-tabs-link-active-bg: #4a525d;
  --ct-nav-tabs-link-active-border-color: #464f5b #464f5b var(--ct-nav-tabs-link-active-bg);
  --ct-navbar-dark-color: rgba(255, 255, 255, 0.55);
  --ct-navbar-dark-hover-color: rgba(255, 255, 255, 0.75);
  --ct-navbar-dark-active-color: #fff;
  --ct-navbar-dark-disabled-color: rgba(255, 255, 255, 0.25);
  --ct-navbar-dark-toggler-border-color: rgba(255, 255, 255, 0.1);
  --ct-navbar-light-color: #d3d7db;
  --ct-navbar-light-hover-color: #e3eaef;
  --ct-navbar-light-active-color: rgba(255, 255, 255, 0.9);
  --ct-navbar-light-disabled-color: #aab8c5;
  --ct-dropdown-color: #aab8c5;
  --ct-dropdown-bg: #3b444e;
  --ct-dropdown-border-color: #353e48;
  --ct-dropdown-divider-bg: #4d5662;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #aab8c5;
  --ct-dropdown-link-hover-color: #d9d9d9;
  --ct-dropdown-link-hover-bg: #4c5460;
  --ct-dropdown-link-active-color: #fff;
  --ct-dropdown-link-active-bg: #7a8089;
  --ct-dropdown-link-disabled-color: #ced4da;
  --ct-dropdown-dark-color: #464f5b;
  --ct-dropdown-dark-bg: #e3eaef;
  --ct-dropdown-dark-border-color: var(--ct-dropdown-border-color);
  --ct-dropdown-dark-divider-bg: var(--ct-dropdown-divider-bg);
  --ct-dropdown-dark-link-color: var(--ct-dropdown-dark-color);
  --ct-dropdown-dark-link-hover-color: #fff;
  --ct-dropdown-dark-link-hover-bg: rgba(255, 255, 255, 0.15);
  --ct-dropdown-dark-link-active-color: var(--ct-dropdown-link-active-color);
  --ct-dropdown-dark-link-active-bg: var(--ct-dropdown-link-active-bg);
  --ct-dropdown-dark-link-disabled-color: #aab8c5;
  --ct-dropdown-dark-header-color: #aab8c5;
  --ct-pagination-color: #8391a2;
  --ct-pagination-bg: #3f4851;
  --ct-pagination-border-color: #464f5b;
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: #37404a;
  --ct-pagination-focus-box-shadow: 0 0 0 0.1rem var(--ct-input-btn-focus-color);
  --ct-pagination-hover-color: #f1f1f1;
  --ct-pagination-hover-bg: #474f58;
  --ct-pagination-hover-border-color: #464f5b;
  --ct-pagination-disabled-color: #8391a2;
  --ct-pagination-disabled-bg: #4a555f;
  --ct-pagination-disabled-border-color: #4a555f;
  --ct-card-border-color: #4d5662;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #464f5b;
  --ct-card-bg: #37404a;
  --ct-accordion-color: #6c757d;
  --ct-accordion-border-color: rgba(0, 0, 0, 0.125);
  --ct-accordion-button-active-bg: #eef0fd;
  --ct-accordion-button-active-color: #4b62cf;
  --ct-accordion-button-focus-border-color: var(--ct-input-focus-border-color);
  --ct-accordion-button-focus-box-shadow: 0 0 0 0.1rem var(--ct-input-btn-focus-color);
  --ct-tooltip-color: #37404a;
  --ct-tooltip-bg: #dee2e6;
  --ct-popover-bg: #37404a;
  --ct-popover-border-color: #464f5b;
  --ct-popover-header-bg: #3c4651;
  --ct-popover-header-color: #dee2e6;
  --ct-popover-body-color: #dee2e6;
  --ct-popover-arrow-color: #37404a;
  --ct-popover-arrow-outer-color: #464f5b;
  --ct-toast-background-color: #404954;
  --ct-toast-border-color: rgba(241, 241, 241, 0.12);
  --ct-toast-header-background-color: rgba(64, 73, 84, 0.2);
  --ct-toast-header-border-color: rgba(241, 241, 241, 0.05);
  --ct-badge-color: #fff;
  --ct-modal-content-bg: #3b444e;
  --ct-modal-content-box-shadow-xs: var(--ct-box-shadow-sm);
  --ct-modal-content-box-shadow-sm-up: var(--ct-box-shadow);
  --ct-modal-backdrop-bg: rgba(50, 50, 51, 0.16);
  --ct-modal-header-border-color: #515c69;
  --ct-modal-footer-border-color: #515c69;
  --ct-progress-bg: #464f5b;
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #536de6;
  --ct-list-group-bg: var(--ct-card-bg);
  --ct-list-group-border-color: #4d5662;
  --ct-list-group-hover-bg: #404954;
  --ct-list-group-disabled-color: #8391a2;
  --ct-list-group-disabled-bg: #404954;
  --ct-list-group-action-color: #aab8c5;
  --ct-list-group-action-active-color: #dee2e6;
  --ct-list-group-action-active-bg: #404954;
  --ct-thumbnail-bg: #464f5b;
  --ct-thumbnail-border-color: #464f5b;
  --ct-thumbnail-box-shadow: var(--ct-box-shadow-sm);
  --ct-figure-caption-color: #ced4da;
  --ct-breadcrumb-divider-color: #8391a2;
  --ct-breadcrumb-active-color: #aab8c5;
  --ct-carousel-control-color: #fff;
  --ct-carousel-indicator-active-bg: #fff;
  --ct-carousel-caption-color: #fff;
  --ct-carousel-dark-indicator-active-bg: #000;
  --ct-carousel-dark-caption-color: #000;
  --ct-btn-close-color: #e3eaef;
  --ct-code-color: #35b8e0;
  --ct-kbd-color: #fff;
  --ct-kbd-bg: #f1f1f1;
  --ct-pre-color: #aab8c5;
  --ct-bg-leftbar: #3a444e;
  --ct-menu-item: #cedce4;
  --ct-menu-item-hover: #536de6;
  --ct-menu-item-active: #536de6;
  --ct-bg-topbar: #3a444e;
  --ct-bg-topbar-search: #464f5b;
  --ct-nav-user-bg-topbar: #45515d;
  --ct-nav-user-border-topbar: #4a5764;
  --ct-bg-dark-topbar: #3a444e;
  --ct-bg-dark-topbar-search: #464f5b;
  --ct-nav-user-bg-dark-topbar: #45515d;
  --ct-nav-user-border-dark-topbar: #4a5764;
  --ct-rightbar-bg: #37404a;
  --ct-rightbar-title-bg: #536de6;
  --ct-rightbar-title-color: #fff;
  --ct-rightbar-title-btn-bg: #6980e9;
  --ct-rightbar-title-btn-color: #fff;
  --ct-rightbar-overlay-bg: #000;
  --ct-bg-detached-leftbar: #37404a;
  --ct-bg-leftbar-gradient: linear-gradient(135deg, #6379c3 0%, #546ee5 60%);
  --ct-bg-topnav: #37404a;
  --ct-boxed-layout-bg: #3e4853;
  --ct-help-box-light-bg: rgba(255, 255, 255, 0.1);
  --ct-help-box-dark-bg: #536de6;
  --ct-nav-pills-bg: #404954;
  --ct-custom-accordion-title-color: #8391a2;
  --ct-dragula-bg: #404954;
  --ct-form-wizard-header-bg: #404954;
  --ct-text-title-color: #fff;
  --ct-page-title-color: #fff;
  --ct-card-loader-bg: #f1f1f1;
  --ct-chat-primary-user-bg: #404954;
  --ct-chat-secondary-user-bg: #404954;
  --ct-auth-bg: #404954;
  --ct-apex-grid-color: #404954;
  --ct-hero-bg: linear-gradient(to bottom, #697ded, #5e30c1); }
