@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif; }

*:focus {
  outline: none; }

::-webkit-scrollbar {
    display: none; }

body {
  font-family: var(--ct-body-font-family);
  font-size: var(--ct-body-font-size);
  font-weight: var(--ct-body-font-weight);
  line-height: var(--ct-body-line-height);
  color: var(--ct-body-color);
  text-align: var(--ct-body-text-align);
  background-color: var(--ct-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }
