@import '../../Styles/var';

// Card
.cardContainer {
  width: 100%; }

.cardFrame {
  padding: 20px 30px;
  border-radius: 2px;
  box-shadow: var(--ct-card-box-shadow);
  background-color: var(--ct-card-bg);
  @media only screen and (max-width: $mobile-screen-width) {
    padding: 20px; } }

// Card Header
.cardHeaderContainer {
  margin-bottom: 25px; }

// Card Content
.cardHeaderContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
