// Button
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1.3px;
  padding: 5px;
  font-size: 20px;
  border-radius: 2px;
  cursor: pointer;
  transition: ease-in-out .2s;
  color: var(--ct-gray-200);
  path {
    fill: var(--ct-gray-200); }
  border-color: var(--ct-secondary);
  background-color: var(--ct-secondary);
  &:hover {
    background-color: transparent;
    color: var(--ct-secondary); }
  &[variant='primary'] {
    border-color: var(--ct-primary);
    background-color: var(--ct-primary);
    &:hover {
      color: var(--ct-primary);
      path {
        fill: var(--ct-primary); }
      background-color: transparent; } }
  &[variant='danger'] {
    border-color: var(--ct-danger);
    background-color: var(--ct-danger);
    &:hover {
      color: var(--ct-danger);
      path {
        fill: var(--ct-danger); }
      background-color: transparent; } }
  &[variant='outline-secondary'] {
    color: var(--ct-secondary);
    path {
      fill: var(--ct-secondary); }
    background-color: transparent;
    &:hover {
      color: var(--ct-gray-200);
      border-color: var(--ct-secondary);
      background-color: var(--ct-secondary);
      path {
        fill: var(--ct-gray-200); } } } }
