// Home Grid
.homeGridContainer {
  display: grid;
  margin: 15px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  @media only screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr; }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr; }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr; } }
