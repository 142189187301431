@import '../../Styles/var';

// CardForm
.cardFormWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: $mobile-screen-width) {
    background-color: var(--ct-card-bg); } }

.cardFormContainer {
  padding: 25px 35px;
  border-radius: 2px;
  box-shadow: var(--ct-card-box-shadow);
  background-color: var(--ct-card-bg);
  width: 480px;
  @media only screen and (max-width: $mobile-screen-width) {
    box-shadow: none;
    width: 100%;
    padding: 25px 15px;
    min-width: 0; } }

// FormSeparatorOr
.cardFormOrSeparator {
  position: relative;
  width: 100%;
  margin: 15px 0;
  text-align: center;
  &::before, &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 40%;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--ct-text-muted); }
  &::before {
    left: 0; }
  &::after {
    right: 0; }
  label {
    color: var(--ct-text-muted); } }
