@import '../Styles/var';

// Template
.templateContainer {
  display: grid;
  grid-template-areas: "header header" "sidebar content";
  grid-auto-rows: 65px calc(100vh - 65px);
  grid-auto-columns: 150px auto;
  height: 100vh;
  overflow: hidden;
  @media only screen and (max-width: $mobile-screen-width) {
    grid-template-areas: "header header" "content content";
    transition: ease .05s; } }

// Template Content
.templateContent {
  grid-area: content;
  padding: 25px;
  overflow-y: scroll;
  @media only screen and (max-width: $mobile-screen-width) {
    padding: 15px 10px; } }

// Template Top Bar
.templateTopBar {
  margin-bottom: 30px; }
