// Flex
.flex {
  display: flex; }

.flex-column {
  flex-direction: column; }

.flex-justify-start {
  justify-content: start; }

.flex-justify-center {
  justify-content: center; }

.flex-justify-end {
  justify-content: end; }

.flex-justify-space-between {
  justify-content: space-between; }

.flex-justify-space-around {
  justify-content: space-around; }

.flex-align-start {
  align-items: start; }

.flex-align-center {
  align-items: center; }

.flex-align-end {
  align-items: end; }

.flex-grow {
  flex-grow: 1; }

// Margin
.mh-1 {
  margin-left: 5px;
  margin-right: 5px; }

.mh-2 {
  margin-left: 10px;
  margin-right: 10px; }

.mh-3 {
  margin-left: 15px;
  margin-right: 15px; }

.mh-4 {
  margin-left: 20px;
  margin-right: 20px; }

.mv-1 {
  margin-top: 5px;
  margin-bottom: 5px; }

.mv-2 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mv-3 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mv-4 {
  margin-top: 20px;
  margin-bottom: 20px; }
